import React from 'react'

const StatusInfo = ({ date, text }) => {
  return (
    <div className="status-info">
      <h2 className="status-title">{date}</h2>
      <p className="status-description">{text}</p>
    </div>
  )
}

export default StatusInfo
