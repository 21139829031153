import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import cx from 'classnames'

const Banner = ({ leftIcon, text, variant }) => {
  const t = useTranslations()
  return (
    <div className={cx(`banner banner-${variant}`)}>
      {leftIcon ? <div className="banner-leftIcon">{leftIcon}</div> : null}
      <p className="banner-text">{t(text)}</p>
    </div>
  )
}

Banner.propTypes = {
  variant: PropTypes.oneOf(['neutral', 'info', 'success', 'warning', 'error']),
  leftIcon: PropTypes.node,
  text: PropTypes.string,
}

export default Banner
