import React from 'react'

const StatusBar = ({ date }) => {
  return (
    <div className="status-bar">
      <div className="bar"></div>
      <div className="tooltip">
        <h4 className="tooltip-title">{date}</h4>
        <p className="tooltip-description">No downtime recorded on this day</p>
      </div>
    </div>
  )
}

export default StatusBar
