import React from 'react'
import StatusBar from './StatusBar'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { formatDate, addDay } from '../../utils'

const StatusItem = ({ title }) => {
  const breakpoints = useBreakpoint()
  const number = breakpoints.xs ? 30 : breakpoints.md ? 60 : 90
  const today = new Date()
  let statusItems = []
  for (let i = 0; i < number; i++) {
    const date = addDay(today, -(number - (i - 1)))

    statusItems.push(<StatusBar key={i} date={formatDate(date)} />)
  }

  return (
    <div className="status-item">
      <div className="status-header">
        <p className="status-title">{title}</p>
        <p className="status-flag success">Operational</p>
      </div>
      <div className="status-bars">{statusItems}</div>
      <div className="status-legend">
        <div className="status-legendItem">
          <span>{number} days ago</span>
        </div>
        <div className="status-legendItem">
          <span>100% uptime</span>
        </div>
        <div className="status-legendItem">
          <span>Today</span>
        </div>
      </div>
    </div>
  )
}

export default StatusItem
