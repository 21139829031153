import React, { useEffect } from 'react'
import Banner from '../components/banners/Banner'
import StatusInfo from '../components/status/StatusInfo'
import StatusItem from '../components/status/StatusItem'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import logo from '../../assets/images/aucta-logos/aucta-logo.svg'
import Link from '../components/links/LinkLocalized'
import { ReactComponent as LeftArrrow } from '../../assets/icons/icon-arrow-left-sm.svg'
import { ReactComponent as IconCheck } from '../../assets/icons/icon-circle-check.svg'
import useScrollRevealEffect from '../../hooks/effects/useScrollRevealEffect.js'

const StatusPageView = ({ incidents }) => {
  const breakpoints = useBreakpoint()
  const number = breakpoints.xs ? 30 : breakpoints.md ? 60 : 90
  const cardRef = useScrollRevealEffect()
  const statusComponents = incidents.map(inc => (
    <StatusInfo key={inc.date} date={inc.date} text={inc.text} />
  ))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="status-page" ref={cardRef}>
      <div className="wrapper">
        <Link to="/" className="logo">
          <img className="logo-img " alt="Aucta" src={logo} />
          <p className="status-name">Status</p>
        </Link>
        <Banner
          variant="success"
          text="All systems are operational"
          leftIcon={<IconCheck />}
        />
        <p className="status-caption">Uptime over the last {number} days</p>
        <div className="status-box">
          <StatusItem title="Website" />
          <StatusItem title="Manager" />
          <StatusItem title="Editor" />
          <StatusItem title="Player" />
          <StatusItem title="Backend" />
        </div>
        <div className="status-extra">
          <h2 className="status-title">Past incidents</h2>
          {statusComponents}
          <Link className="back" to="/">
            <LeftArrrow />
            Go back
          </Link>
        </div>
      </div>
    </div>
  )
}

export default StatusPageView
