import React from 'react'
import StatusPageView from '../views/StatusPageView'
import { formatDate, addDay } from '../utils'

const StatusPage = () => {
  const today = new Date()

  const incidents = [
    { date: formatDate(today), text: 'No incidents reported today' },
    {
      date: formatDate(addDay(today, -1)),
      text: 'No incidents reported today',
    },
    {
      date: formatDate(addDay(today, -2)),
      text: 'No incidents reported today',
    },
    {
      date: formatDate(addDay(today, -3)),
      text: 'No incidents reported today',
    },
    {
      date: formatDate(addDay(today, -4)),
      text: 'No incidents reported today',
    },
  ]

  return <StatusPageView incidents={incidents} />
}

export default StatusPage
